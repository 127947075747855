import { useEffect, useState } from "react";
import Menu from "./Menu";
import { useRecoilState } from "recoil";
import { desksList0, officesList0 } from "../helpers/states";
import { fetchDesks, fetchOffices } from "../helpers/funcs";

const PrivateLayout = ({children}) => {
    const [officesList, setOfficesList] = useRecoilState(officesList0)
    const [desksList, setDesksList] = useRecoilState(desksList0)



    useEffect(() => {
        const fetchData = async () => {
            setOfficesList(await fetchOffices())
        }
        if (officesList === undefined) {
            fetchData()
        }
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            setDesksList(await fetchDesks())
        }
        if (officesList === undefined) {
            fetchData()
        }
    }, [])

    
    return (
        <div className="back-color min-h-screen flex flex-row">
            <Menu />
            <div className="flex flex-col w-[calc(100%-70px)] overflow-x-hidden p-4">
                <div>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default PrivateLayout;