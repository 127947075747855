import { get, put } from "./apiHandler";
import { Store } from 'react-notifications-component';

export const formDataToObject = (formData) => {
    let object = {};
    formData.forEach((value, key) => {
        object[key] = value;
    });
    return object;
};
  

export const generateRandomString = () => {
    var result = '';
    var characters = 'abcdefghijklmnopqrstuvwxyz';

    for (var i = 0; i < 7; i++) {
        var randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
    }

    return result;
}


export const areListEqual = (list1, list2) =>  {
    if (list1.length !== list2.length) {
        return false;
    }
    for (let i = 0; i < list1.length; i++) {
        if (typeof list1[i] === typeof '' ) {
            if (list1[i] !== list2[i]) {
                return false;
            }
        } else if (list1[i] === null && list2[i] === null) {
            return true
        } else {
            areListEqual(Object.values(list1[i]), Object.values(list2[i]))
        }

    }
    return true;
}



export const updateLevel = async (clientId, levelNum) => {
    const result = (await put(`/users/${clientId}?access_level=${levelNum}`))
    if (result.ok) {
        Store.addNotification({
            message: "Lead's level successfully updated",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            dismiss: {
              duration: 5000,
            }
        });
    } else {
        Store.addNotification({
            message: result.data.message || `somthing went wrong`,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            dismiss: {
              duration: 5000,
            }
        });
    }
}


export const logout_func = async () => {
    localStorage.removeItem('authToken')
    window.location.reload(true)
}



export const updateFile_nameStorge = async (list) => {
    var leadsFilter = JSON.parse(localStorage.getItem('leadsFilter'))
    if (leadsFilter !== null) {
        var filter = JSON.parse(leadsFilter['filter'])
        filter['file_name'] = list
        leadsFilter['filter'] = JSON.stringify(filter)
        localStorage.setItem('leadsFilter', JSON.stringify(leadsFilter))
    }
}


export const addCommas = (number) => {
    if (number !== undefined) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return number
}


export const fetchOffices = async () => {
    const result = await get('/offices', {all: 'true'})
    if (result.ok) {
        return result.data
    } else {
        return []
    }
}

export const fetchDesks = async () => {
    const result = await get('/desks', {all: 'true'})
    if (result.ok) {
        return result.data
    } else {
        return []
    }
}


export const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}



////////////////////////// LEADS ///////////////////////////////

export const updateLead = async (leadId, data) => {
    const result = await put(`/lead/${leadId}`, data)
    if (result.ok) {
        Store.addNotification({
            message: "Lead successfully updated",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            dismiss: {
              duration: 5000,
            }
        });
    } else {
        Store.addNotification({
            message: result.data.message || `somthing went wrong`,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            dismiss: {
              duration: 5000,
            }
        });
    }
}


//////////////////// USER ///////
export const updateUser = async (userId, data) => {
    const result = await put(`/users/${userId}`, data)
    if (result.ok) {
        Store.addNotification({
            message: "user successfully updated",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            dismiss: {
              duration: 5000,
            }
        });
    } else {
        Store.addNotification({
            message: result.data.message || `somthing went wrong`,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            dismiss: {
              duration: 5000,
            }
        });
    }
}


