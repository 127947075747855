import Select, { components } from 'react-select';
import { useEffect, useState } from "react"
import { get } from "../../helpers/apiHandler"
import isoToCountry from '../../docs/isoToCountry';
import Skeleton from 'react-loading-skeleton'
import { selectedNumber0 } from '../../helpers/states';
import { useRecoilState } from 'recoil';

const CustomOption = ({ data, ...props }) => {
    return (
      <components.Option {...props}>
        <div className="flex flex-row items-center space-x-1">
          <img
            src={`/flags/${data.value.toLowerCase()}.png`}
            alt={data.label}
            className="w-5 h-5 object-cover rounded-full"
          />
          <span>
            {data.label}
          </span>
        </div>
      </components.Option>
    );
};


const SelectCountryNum = ({isSm}) => {
    const [selectedNumber, setSelectedNumber] = useRecoilState(selectedNumber0)
    const [data, setData] = useState()
    useEffect(() => {
        getCountries()
    }, [])

    const getCountries = async () => {
        const res = await get('/numbers/countries')
        if (res.ok) {
            // if (res.data.length > 0) {
            //     setSelectedNumber({value: res.data[0], label: isoToCountry[res.data[0].toUpperCase()]})
            // }
            setData(res.data.map(isoCoun => {
                return { value: isoCoun, label: isoToCountry[isoCoun.toUpperCase()]}
            }))
        } else {

        }
    }
    useEffect(() => {
        if (selectedNumber === undefined && data !== undefined) {
            setSelectedNumber(data[0])
        }
    }, [data])
    const handleListChange = (selectedOptions) => {
        setSelectedNumber(selectedOptions);
    };


    const customStyles = {
        control: (provided) => ({
          ...provided,
          height: isSm ? '38px' : '60px',
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '16px',
          }),
      };


    return (
        <div className={`text-gray-800 text-[14px] flex ${isSm ? 'flex-row-reverse' : 'flex-row'}`}>

            <div className='flex items-center'>
                {
                    selectedNumber !== undefined ? (
                        <img
                            src={`/flags/${selectedNumber.value?.toLowerCase()}.png`}
                            alt={data?.label}
                            className="h-5 mx-2"
                        />

                    ) : (
                        <Skeleton className='w-10 h-7 mr-2' />
                    )
                }
            </div>

            <Select
                value={selectedNumber}
                onChange={handleListChange}
                styles={customStyles}
                closeMenuOnSelect={true}
                name="office"
                options={data}
                components={{ Option: CustomOption}}
                className="basic-single z-30 w-[165px] text-[18px] font-semibold"
                classNamePrefix="select"
                menuPlacement="top"
            />
        </div>
    )
}


export default SelectCountryNum