import { useEffect, useState } from "react";
import { GrDocumentExcel } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import SearchInp from "../../Components/handle_select_one/SearchInp";
import { FaPlus } from "react-icons/fa6";
import { FaFileAlt } from "react-icons/fa";
import { get } from "../../helpers/apiHandler";
import { Store } from 'react-notifications-component';
import Skeleton from 'react-loading-skeleton'
import { choosedFiles0, currentUser0, files0 } from "../../helpers/states";
import { useRecoilState } from "recoil";


const FilesPage = ({}) => {
    const Navigate = useNavigate()
    const [text, setText] = useState('')
    const [files, setFiles] = useRecoilState(files0)

    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)

    const [choosedFiles, setChoosedFiles] = useRecoilState(choosedFiles0)
    
    const choosedFile = (fileName) => {
        setChoosedFiles(prev => {
            if (prev.includes(fileName)) {
                return prev.filter(item => item !== fileName)
            } else {
                return [...prev, fileName]
            }
        })
    }

    useEffect(() => {
        localStorage.setItem('choosedFiles', JSON.stringify(choosedFiles))
    }, [choosedFiles])



    useEffect(() => {
        if (files === null) {
            getNames()
        }
    }, [])
    const getNames = async () => {
        const res = await get('/leads/files')
        if (res.ok) {
            setFiles(res.data)
        } else {
            setFiles([])
            Store.addNotification({
                message: `somthing went wrong`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });
        }
    }

    return (
        <div className="flex flex-col space-y-4 max-w-[1400px] mx-auto">

            <div className="card flex flex-row justify-between space-x-4 w-full">
                <div className="w-full max-w-[300px]">
                    <SearchInp text={text} setText={setText} />
                </div>
                {
                    currentUser.access_level === 4 && (
                        <button onClick={() => Navigate('/files/import')} className="but but-basic flex flex-row items-center space-x-1">
                            <FaPlus />
                            <span className='font-semibold tracking-wide'>import file</span>
                        </button>
                    )
                }
            </div>

            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4 text-[14px]">
                <div onClick={() => setChoosedFiles([])} className={`card grow hover:bg-blue-200 active:bg-blue-300 ${choosedFiles.length === 0 ? 'bg-blue-100 hover:bg-blue-300' : ''} cursor-pointer h-[150px]  flex flex-col items-center justify-around`}>
                    <div className="text-gray-600 text-[50px]">
                        <FaFileAlt />
                    </div>
                    <span className="text-center font-medium text-gray-700">All</span>
                </div>
                {
                    files === null && (
                        <div className="card grow hover:bg-blue-100 cursor-pointer h-[150px] flex flex-col items-center justify-around">
                            <div className="text-gray-600 text-[50px]">
                                <Skeleton className='w-[55px]' circle/>
                            </div>
                            <Skeleton className='w-[55px]' />
                        </div>
                    )
                }
                {
                    files?.filter((file) => file['file_name'].toLowerCase().includes(text.toLowerCase())).map((file, f) => (
                        <div key={f} onClick={() => choosedFile(file['file_name'])} className={`card grow hover:bg-blue-200 active:bg-blue-300 ${choosedFiles.includes(file['file_name']) ? 'bg-blue-100 hover:bg-blue-300' : ''}  cursor-pointer h-[150px] flex flex-col items-center justify-around overflow-hidden`}>
                            <div className="text-gray-600 text-[50px]">
                                <GrDocumentExcel />
                            </div>
                            <span className="text-center font-medium text-gray-700 leading-4 mt-2">{file['file_name']}</span>
                            <span className="text-sm text-gray-400 font-semibold">{file['created_at']}</span>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default FilesPage

