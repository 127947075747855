
import React, { useEffect, useState } from 'react'
import readXlsxFile from 'read-excel-file'
import Papa from 'papaparse';
import { useRef } from 'react';
import { useRecoilState } from 'recoil';
// import { notiList0, popWinMsg } from '../../lib/states';
import { CSVLink, CSVDownload } from "react-csv";
import { useNavigate } from 'react-router-dom';
import { post } from '../../helpers/apiHandler';

const ths = ['brand', 'lead_source', 'first_name', 'last_name', 'phone', 'email', 'country', 'city', 'address', 'zip', 'lang', 'ftd_date', 'registration_time', 'net_deposit', 'last_time_deposit', 'original_agent', 'original_status', 'birthday', 'comment']
const req_ths = ['first_name', 'phone']

const delay = ms => new Promise(res => setTimeout(res, ms));

export default function Import() {
    const router = useNavigate();
    const myRef = useRef(null);
    // const [notiList, setNotiList] = useRecoilState(notiList0)
    const [line0, setLine0] = useState([])


    const handleFile = async (e) => {
        const inp = e.target;
        const data = await getRows(inp)
        setLine0(data[0])
    }
    const getRows = async (inp) => {
        const fileParts = inp.files[0].name.split('.')
        const fileType = fileParts[fileParts.length - 1]


        if (fileType == 'xlsx') {
            return await readXlsxFile(inp.files[0])
        } else if (fileType == 'csv') {
            var ret = await getCsvData(inp.files[0])
            return ret.slice(0, -1)
        } else {
            // setNotiList(prevValue => [...prevValue, { text: 'file type not support', isSucc: false }])
        }
    }

    // const [popWin2, setPopWin2] = useRecoilState(popWinMsg)
    const handleUpload = async (e) => {
        e.preventDefault();
        var all = []
        const inp = myRef.current
        var rows = await getRows(inp)
        var formData = new FormData(e.target);
        var isOk = true
        for (var r = 1; r < rows.length; r++) {
            var obj = {}
            for (var t in ths) {
                var index = rows[0].findIndex(row => row === formData.get(ths[t]))
                if (formData.get(ths[t]) !== '') {
                    if (ths[t] === 'phone' || ths[t] === 'net_deposit' || ths[t] === "first_name") {
                        obj[ths[t]] = "" + rows[r][index]
                    } else {
                        obj[ths[t]] = rows[r][index]
                    }
                } else {
                    obj[ths[t]] = ''
                }
            }
            if (typeof obj["first_name"] !== typeof "") continue;
            if (obj['last_name'] == '') {
                var nameParts = obj['first_name'].split(" ").filter((item) => item.length);
                if (nameParts.length > 1) {
                    obj['first_name'] = nameParts.slice(0, -1).join(" ");
                    obj['last_name'] = nameParts.slice(-1)[0];
                }
            }
            obj['file_name'] = inp.files[0].name;


            for (var t in req_ths) {
                if (formData.get(req_ths[t]) === '') {
                    isOk = false;
                }
            }

            all.push(obj)
        }

        if (isOk) {
            var totalInsertLeads = 0;
            var z = 0;
            while (z < all.length) {
                console.log(all.length + "/" + z)
                const result = await post('/leads', {
                    "leads": all.slice(z, z + 3000),
                    "office_id": choosedOfficeId
                });
                totalInsertLeads += result.data.inserted_leads;
                z += 3000;
            }

            
            console.log('totalInsertLeads: ', totalInsertLeads)
            // setNotiList(prevValue => [...prevValue, { text: 'Done!!', isSucc: true }])

            // setPopWin2(prevValue => {
            //     return {
            //         ...prevValue,
            //         disable: true,
            //         msg: `
            //             Inserted leads: ${totalInsertLeads} <br />
            //         `,
            //         // Existing leads: ${result.data.existing_leads.length} <br />
            //         // Duplicates ledas: ${result.data.uploaded_duplicates.length}
            //         // func1: result.data.existing_leads.length ? () => {exportDuplicate(result.data.existing_leads); setFileName('Existing leads')} : null,
            //         // func2: result.data.uploaded_duplicates.length ? () => {exportDuplicate(result.data.uploaded_duplicates); setFileName('Duplicates leads')} : null,
            //         func1: null,
            //         func2: null,
            //     }
            // })

            var selectes = e.target.querySelectorAll('select');
            for (var sel of selectes) {
                sel.value = ''
            }
            myRef.current.value = null;
            setLine0([])
        } else {
            // setNotiList(prevValue => [...prevValue, { text: 'Please fill in required fields', isSucc: false }])
        }

    }

    function chunkArray(array, chunkSize) {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    }


    const myObjectRef = useRef(null);
    const [DataExp, setDataExp] = useState([])

    const exportDuplicate = async (duplicateList) => {
        const inp = myRef.current
        var rows = await getRows(inp)
        var finalList = [['lead_source', 'uploaded_at', ...rows[0]]]
        for (var x in duplicateList) {
            var lineTarget = [duplicateList[x].exist_lead_data.lead_source, duplicateList[x].exist_lead_data.created_at, ...rows[duplicateList[x].row]]
            finalList.push(lineTarget)
        }
        setDataExp(finalList)
        await delay(1500);
        myObjectRef.current.click()
    }

    const [fileName, setFileName] = useState('')

    function getCsvData(file) {
        return new Promise((res) => {
            Papa.parse(file, {
                complete: (result) => {
                    res(result.data)
                },
                header: false,
            });
        });
    }

    const [choosedOfficeId, setChoosedOfficeId] = useState(null)

        return (
            <form className='flex flex-col space-y-4' onSubmit={handleUpload}>
                <CSVLink data={DataExp} className='hidden' filename={fileName} >
                    <div ref={myObjectRef}>
                    </div>
                </CSVLink>
                <div className='card p-4 flex flex-row justify-between items-center'>

                    <div className='flex flex-row space-x-2 w-[500px]'>
                        <div className='border rounded-lg w-1/2'>
                            <input ref={myRef} type="file" onChange={handleFile}
                                className="
                            text-md 
                            text-gray-400
                            file:but-file
                            file:cursor-pointer 
                            " />
                        </div>
                    </div>


                    <button type='submit' className='but but-basic' disabled={line0.length == 0}>Upload</button>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6'>
                    {
                        ths.map((str, zi) => (
                            <div key={zi} className='card p-4 flex flex-col space-y-4'>
                                <div className='font-bold'>
                                    <div className={`"border ${req_ths.includes(str) ? 'bg-blue-400' : 'bg-blue-200'} h-11  border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}>
                                        {str}
                                    </div>
                                </div>
                                <div className=''>
                                    <select name={str} disabled={line0.length == 0} className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                        <Opts list={line0} str={str} />
                                    </select>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </form>
        )
    // }
}

function Opts({ list, str }) {
    if (list.length > 0) {
        return (
            <>
                <option selected></option>
                {
                    list.map((item, zi) => {
                        return <option key={zi} selected={item.replace(/[^a-zA-Z]+/gi, "").toLowerCase() == str.replace(/[^a-zA-Z]+/gi, "").toLowerCase()} value={item}>{item}</option>
                    })
                }
            </>
        )
    } else {
        return (
            <option></option>
        )
    }
}